header {
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 16px solid $brand-dark-blue;
  h3 {
    margin-bottom: 0;
    font-weight: normal;
    @media screen and (max-width: 39.9375em) {
      margin-top: 1rem;
    }
  }
}