@import 'util/util';

// Import Bourbon Mixins
@import 'bourbon';

// Import Google Fonts
@import url('https://fonts.googleapis.com/css?family=Ubuntu:300,400,500,700');

// Import overwrite for theme settings
@import 'custom_settings';

// import all of the foundation mixins, but none of the actual code
// this is coming from node_modules/foundation-sites/scss/foundation.scss
@import 'foundation';

// Everything
@include foundation-everything(true);

// Font Awesome
@import 'fa_variables';
@import 'font-awesome';

// General Styles
@import 'header';

a,
a:visited,
button {
  @include transition(all 0.3s ease-in-out);
}

#cookieBanner {
  position: fixed;
  z-index: 9999;
  bottom: 0px;
  width: 100%;
  padding: 20px 17px;
  background-color: $mid-green;
  color: white;
  text-align: center;
  font-size: 14px;
  @media screen and (min-width: 40em) {
    text-align: left;
    padding: 25px 17px 25px 17px;
  }
  @media screen and (min-width: 64em) {
    line-height: 35px;
  }
  p {
    margin-bottom: 0;
  }
  a,
  a:visited {
    color: white;
    text-decoration: underline;
  }
  a:hover,
  a.active {
    text-decoration: none;
  }
  .button {
    margin: 0 10px 10px 10px;
    padding: 8px 15px 8px 15px !important;
    text-decoration: none;
    text-transform: uppercase;
    float: none;
    z-index: 10;
    position: relative;
    font-size: 12px;
    @media screen and (min-width: 40em) {
      float: right;
      margin: 0 10px 0 10px;
    }
  }
}


section.main {
  min-height: 60vh;
  padding-top: 36px;
  padding-bottom: 36px;
  form.translate {
    .fieldset {
      legend {
        font-weight: 600;
      }
      label {
        font-weight: 400;
        font-size: 0.75rem;
        @media screen and (min-width: 40em) {
          font-size: 1rem;
        }
      }
    }
    .is-invalid-label {
      .form-error {
        display: block !important;
      }
    }
  }

  .image-click {
    cursor: pointer;
  }
  .image-border {
    margin-bottom: 1rem;
    border: 2px solid $brand-dark-blue;
  }
}
.reveal {
  &.large {
    height: 90vh;
  }
  .close-button {
    width: 40px;
    height: 36px;
    background-color: white;
    .fa {
      color: $alert-color;
    }
  }
}


@import 'footer';