.footer {
  padding-top: 36px;
  padding-bottom: 72px;
  border-top: 16px solid $brand-dark-blue;
  background-color: $brand-light-blue;
  color: white;
  a,
  a:visited {
    color: white;
    text-decoration: none;
  }
  a:hover,
  a.active {
    color: $mid-green;
    text-decoration: none;
  }
  h4,
  h5 {
    color: white;
  }
  .border-top {
    border-top: 1px solid $mid-green;
  }
  .footer-menu {
    font-size: 0.875rem;
    margin-bottom: 1.25rem;
    @media screen and (min-width: 40em) {
      margin-bottom: 0;
    }
    .menu {
      > li {
        > a {
          padding: 0.5rem 0.5rem 0.5rem 0rem;
        }
      }
    }
  }
  .footer-legal-menu {
    font-size: 0.875rem;
    .menu {
      > li {
        > a {
          padding: 0.5rem 0.5rem 0.5rem 0rem;
          @media screen and (min-width: 40em) {
            padding: 1rem 1rem 1rem 0rem;
          }
        }
      }
    }
  }
  .copyright {
    color: $white;
    font-size: 0.875rem;
    margin: 0.75rem 0 0 0;
  }
}
